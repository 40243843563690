import React from "react";
//import scroll
import { Link } from "react-scroll";

// import img
import Image from "../assets/img/about.jpg";

const About = () => {
  return (
    <section className="section bg-secondary" id="about">
      <div className="container mx-auto">
        <div className="flex flex-col xl:flex-row gap-24">
          <img
            className="object-cover h-full w-[566px] md:mx-auto lg:mx-0 rounded-2xl"
            src={Image}
            alt=""
          />
          <div className="flex flex-col items-center text-center lg:items-start lg:text-left">
            <div className="flex flex-col">
              <h2 className="text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block">
                Luke Rozon
              </h2>
              <p className="mb-4 text-accent">Freelance Web Developer</p>
              <hr className="mb-8 opacity-5" />
              <p className="mb-8">
                I am a Web Developer currently located in Philadelphia, PA. I
                love working and collaborating with other developers and
                building fully interactive and responsive web apps as well as
                creating e-commerce applications. Experience with taking the
                clients idea and making it a reality. I am also an Interior
                Decorator and Musician which has given me the tools to
                collaborate on projects, hone my attention to detail and follow
                algorithms set out by the customer.
              </p>
            </div>
            <Link
              to="contact"
              activeClass="active"
              spy={true}
              smooth={true}
              duration={500}
              offset={-70}
            >
              <button className="btn btn-md bg-accent hover:bg-secondary-hover transition-all">
                Contact me
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
