//  icons
import {
  FiLinkedin,
  FiGithub,
  FiLayout,
  FiSettings,
  FiMail,
  FiMapPin,
} from 'react-icons/fi';

// companies icons
import UpworkBrandIcon from './assets/img/brands/upwork.png';
import FiverBrandIcon from './assets/img/brands/fiverr.png';


// projects images
import Project1 from './assets/img/projects/tara-portfolio.png';
import Project2 from './assets/img/projects/vapor.png';
import Project3 from './assets/img/projects/ABC.PNG';
import Project4 from './assets/img/projects/p4.webp';
import TodoApp from './assets/img/projects/postgresql-react-todo.png';
import Hvmane from './assets/img/projects/www.hvmane.com_.png';


// skills images
import SkillImg1 from './assets/img/skills/html5.png';
import SkillImg2 from './assets/img/skills/css3.png';
import SkillImg3 from './assets/img/skills/js.png';
import SkillImg4 from './assets/img/skills/reactjs.png';
import SkillImg6 from './assets/img/skills/nodejs.png';
import SkillImg7 from './assets/img/skills/git.png';


// testimonial images
import TestiImage1 from './assets/img/testimonials/tara-img.jpg';

// navigation
export const navigation = [
  {
    name: 'home',
    href: 'home',
  },
  {
    name: 'about',
    href: 'about',
  },
  {
    name: 'portfolio',
    href: 'portfolio',
  },
  {
    name: 'services',
    href: 'services',
  },
  {
    name: 'testimonials',
    href: 'testimonials',
  },
  {
    name: 'contact',
    href: 'contact',
  },
];

// social
export const social = [
  {
    icon: <FiLinkedin />,
    href: 'https://www.linkedin.com/in/lucas-rozon/',
  },
  {
    icon: <FiGithub />,
    href: 'https://github.com/Zym0tic',
  },
];

// companies
export const brands = [
  {
    img: UpworkBrandIcon,
    href: 'https://www.upwork.com/freelancers/~01aef21eb9c1824b96',
  },
  {
    img: FiverBrandIcon,
    href: '',
  },
];

// projects
export const projectsData = [
  {
    id: '1',
    image: Project1,
    name: 'Tarazep.com',
    category: 'front-end',
    description: 'Professional website for Tara Zep. UX/UI made with React, Tailwind, Framer Motion, React Scroll. Also utilized Emailjs API to forward messages from the contact form to the clients email. ',
    href: 'https://tarazep.com'
  },
  {
    id: '6',
    image: Hvmane,
    name: 'Hvmane.com',
    category: 'front-end',
    description: 'Ongoing project for recording artist HVMANE. UX/UI made with React, Tailwind, Framer Motion, React Scroll.',
    href: 'https://hvmane.com'
  },
  {
    id: '5',
    image: TodoApp,
    name: 'To-do Checklist!',
    category: 'full-stack',
    description: 'To-do list application built with PostgreSQL, Express, React, Nodejs, and CSS which allows users to signup/login and add, edit to-do\'s using modals as well as delete the to-dos from their list and track their progress.',
    href: 'https://github.com/Zym0tic/postgreSQL-react-todo'
  },
  {
    id: '4',
    image: Project4,
    name: 'Shop Back End',
    category: 'back-end',
    description: 'MySQL Back-end that can be used for and e-commerce website  to add/create/remove/update products, categories and tags in an online shop. Uses mySQL, Node, insomnia.',
    href: 'https://github.com/Zym0tic/shop-back-end'
  },
  {
    id: '2',
    image: Project2,
    name: 'Vapor',
    category: 'full-stack',
    description: 'Vapor is a video game digital distribution service and storefront by JAFCL. It was launched to provide users a way to purchase online video games as well as provide a membership program to allow members to play games with one another online and offers members cloud storage.',
    href: 'https://github.com/Zym0tic/vapor'
  },
  {
    id: '3',
    image: Project3,
    name: 'Criminal Medals',
    category: 'full-stack',
    description: 'E-commerce storefront for a small jewelry business. Criminal Medals built using MongoDB, Express JS, Node JS, React, Bootstrap, Stripe, using an Apollo Graph QL server. This is an ongoing project still in the development phase.',
    href: 'https://github.com/Zym0tic/criminal-medals'
  },
];

// projects
export const projectsNav = [
  {
    name: 'all',
  },
  {
    name: 'front-end',
  },
  {
    name: 'back-end',
  },
  {
    name: 'full-stack',
  },
];

// skill
export const skills = [
  {
    image: SkillImg1,
  },
  {
    image: SkillImg2,
  },
  {
    image: SkillImg3,
  },
  {
    image: SkillImg4,
  },
  {
    image: SkillImg6,
  },
  {
    image: SkillImg7,
  },
];

// services
export const services = [
  {
    icon: <FiLayout />,
    name: 'Web Design',
    description:
      'I love designing and creating the UI/UX for online applications.',
  },
  {
    icon: <FiSettings />,
    name: 'Web Development',
    description:
      'I thrive in using the latest technologies to bring your ideas to life .',
  },
];

// testimonials
export const testimonials = [
  {
    authorImg: TestiImage1,
    authorText:
      'I hired Lucas to build me a website in an effort to expand and navigate my brand. To be frank I barely gave him direction, but he was able to create for me a website that reflected my personality and aesthetic beautifully, based on my social media presence. Very happy with the out come. 10/10 would recommend',
    authorName: 'Tara Zep',
    authorPosition: 'Artist, Model, Wrestler',
  },
];

// contact
export const contact = [
  {
    icon: <FiMail />,
    title: 'Have a question?',
    subtitle: 'I am here to help you.',
    description: 'Email me at lukerozon@gmail.com',
  },
  {
    icon: <FiMapPin />,
    title: 'Current Location',
    subtitle: 'Philadelphia PA, USA',
    description: 'Serving clients worldwide',
  },
];
