import { React, useState } from "react";
//import emailjs
import { send } from "emailjs-com";
// import contact data
import { contact } from "../data";

const Contact = () => {
  //form submission
  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "Lucas",
    subject: "",
    message: "",
    reply_to: "",
  });
  
  const [sent, setSent] = useState('Send Message')

  const onSubmit = (e) => {
    e.preventDefault();
    /* --- METHOD TO SEND THE MAIL --- */
    send("service_pzxljez", "template_mqp0mfm", toSend, "0I25YihcNkDWx1iIO")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setSent('Sent succesfully!');
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
    };

    const handleChange = (e) => {
      setToSend({ ...toSend, [e.target.name]: e.target.value });
    };


    

    
  return (
    <section className="section bg-primary" id="contact">
      <div className="container mx-auto">
        <div className="flex flex-col items-center text-center">
          <h2 className="section-title before:content-contact relative before:absolute before:opacity-40 before:-top-7 before:-left-40 before:hidden before:lg:block">
            Contact me
          </h2>
          <p className="subtitle">
            For inquiries on how we can work together, or for general
            information please get in contact!
          </p>
        </div>
        <div className="flex flex-col lg:gap-x-8 lg:flex-row">
          <div className="flex flex-1 flex-col items-start space-y-8 mb-12 lg:mb-0 lg:pt-2">
            {contact.map((item, index) => {
              const { icon, title, subtitle, description } = item;
              return (
                <div className="flex flex-col lg:flex-row gap-x-4" key={index}>
                  <div className="text-accent rounded-sm w-14 h-14 flex items-start justify-center mt-2 mb-4 lg:mb-0 text-2xl">
                    {icon}
                  </div>
                  <div>
                    <h4 className="font-body text-xl mb-1">{title}</h4>
                    <p className="mb-1 text-paragraph">{subtitle}</p>
                    <p className="text-accent font-normal ">{description}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <form className="space-y-8 w-full max-w-[780px]" onSubmit={onSubmit}>
            <div className="flex gap-8">
              <input
                className="input"
                type="text"
                placeholder="Your name"
                name="from_name"
                value={toSend.from_name}
                onChange={handleChange}
              />
              <input
                className="input"
                type="email"
                placeholder="Your email"
                name="reply_to"
                value={toSend.reply_to}
                onChange={handleChange}
              />
            </div>
            <input
              className="input"
              type="text"
              placeholder="Subject"
              name="subject"
              value={toSend.subject}
              onChange={handleChange}
            />
            <textarea
              className="textarea"
              placeholder="Your message"
              name="message"
              value={toSend.message}
              onChange={handleChange}
            ></textarea>
            <button className="btn btn-lg bg-accent hover:bg-secondary-hover">
             {sent}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
